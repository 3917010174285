import TYPES from '@/types';

// Application
import LogoutCommand from '@/modules/authentication/application/commands/logout-command';

// Domain
import Inject from '@/modules/shared/domain/di/inject';

export default class SignOutService {
  @Inject(TYPES.LOGOUT_COMMAND)
  readonly logout_command!: LogoutCommand;

  logout = async () => {
    await this.logout_command.execute();
    sessionStorage.clear();
    window.location.assign(process.env.VUE_APP_COLUMBUS_WEB_LOGIN_URL as string);
  }
}
