import store from '@/vue-app/store';

import SignOutService from '@/modules/authentication/application/services/sign-out-service';

export default class HeaderViewModel {
  private scroll_listener_enabled = false;

  scroll = 0;

  get is_authenticated() {
    const authenticated_token = sessionStorage.getItem('authorization');

    return !!authenticated_token;
  }

  get show_go_to_site() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    return store.state.layout.header.go_to_site;
  }

  get header_position() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    return store.state.layout.header.position;
  }

  toggleScrollListener = (header_position: 'relative' | 'sticky') => {
    if (header_position === 'sticky' && !this.scroll_listener_enabled) {
      this.scroll_listener_enabled = true;
      window.addEventListener('scroll', this.updateScroll, { passive: true });
    } else if (header_position === 'relative' && this.scroll_listener_enabled) {
      this.removeScrollListener();
    }
  }

  removeScrollListenerIfExists = () => {
    if (this.scroll_listener_enabled) {
      this.removeScrollListener();
    }
  }

  logout = async () => {
    await new SignOutService().logout();
  }

  goToColumbusSite = () => {
    window.location.href = 'https://columbus.mx/';
  };

  private updateScroll = () => {
    this.scroll = window.scrollY;
  }

  private removeScrollListener() {
    window.removeEventListener('scroll', this.updateScroll);
  }
}
